<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} Institution Type</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  label="Title*"
                  outlined
                  dense :error="$v.institution_type.title.$error"
                  v-model="institution_type.title">
              </v-text-field>
              <span class="text-danger" v-if="$v.institution_type.title.$error">Title is Required</span>
              <span class="text-danger" v-if="errors.title" >**{{errors.title[0]}}</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <ckeditor :config="editorConfig"
                        v-model="institution_type.description"></ckeditor>
              <span class="text-danger" v-if="errors.description" >**{{errors.description[0]}}</span>
              <span class="text-danger" v-if="$v.institution_type.description.$error">Description is Required</span>
            </v-col>
            <v-col cols="12" md="6">
              Status
              <v-switch
                  v-model="institution_type.is_active"
                  :label="institution_type.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="mr-2" text dark
            medium
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            class="btn btn-primary"
            medium
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
  import InstitutionTypesService from "@/services/academic/institution/type/InstitutionTypesService";
  const institutionService = new InstitutionTypesService()
import {required} from "vuelidate/lib/validators";
export default {
  validations:{
    institution_type:{
      title:{required},
      description:{required},

    },
  },
  data() {
    return {
      title: 'Create',
      dialog: false,
      edit: false,
      loading: false,
      states:[],
      errors:[],
      editorConfig: {
        versionCheck: false,
        toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
      },
      institution_type:{
        title:'',
        description:'',
        is_active:1,
     },
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
      this.$v.$reset()
    },
    openDialog() {
      this.dialog = true;
    },
    resetForm(){
      this.institution_type={
        title:'',
        description:'',
        is_active:1,
      };
    },
    createForm() {
      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Create";
    },
    editForm(item) {
      this.openDialog();
      this.edit = true;
      this.institution_type = item;
      this.title = "Edit";
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if (!this.edit) this._create();
        else this.__update();
      }
    },
    _create: function () {
      this.loading = true;
      institutionService
          .create(this.institution_type)
          .then(response => {
            this.$snotify.success("Institution Type created successfully");
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          })
    },
    __update: function () {
      this.loading = true;
      institutionService
          .update(this.institution_type.id, this.institution_type)
          .then(response => {
            this.$snotify.success("Institution Type updated successfully");
            this.closeDialog();
            this.resetForm();
            this.errors = [];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          })
    },

    __Detail() {
      if(this.institution_type_id != null || this.institution_type_id !=undefined ){
        institutionService
            .show(this.institution_type_id)
            .then(response=> {
              this.state = response.data.state;

            })
      }
    }
  },
  mounted() {
    this.__Detail();
  },
  computed:{
    institution_type_id () {
      return this.$route.params.institution_type_id;
    },
    titleText(){
      if(this.institution_type_id!=null || this.institution_type_id!=undefined)
        return this.title="Edit";
      else
        return this.title="Create New"
    }
  }
}
</script>
