<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Institution Type</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Institution Type
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn  @click="__create()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add New
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="12" md="6">
                  <v-text-field label="Lookup by Name, Short Name" v-on:keyup.enter="__search" clearable v-model="search.title" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select
                      label="Status"
                      v-model="search.is_active"
                      v-on:keyup.enter="__search"
                      @input="search.is_active = $event !== null ? $event : ''"
                      item-text="name"
                      item-value="value"
                      :items="status"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                      <v-btn :loading="isLoading"  @click="__search" class="mt-1 btn btn-primary text-white text-left" >
                  <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                  Search
                </v-btn>
                  </v-col>

              </div>
            </div>

            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="px-3">
                  <th class="px-3" :class="sortedClass('name')"  @click="sortBy('name')"><strong>Name</strong></th>
                  <th><strong>Status</strong></th>
                  <th class="pr-3 text-center"><strong>ACTION</strong></th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item, index) in sortedItems" :key="index">
                    <td class="px-2">
                      <a @click="__edit(item)" class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        {{item.title }}
                      </a>
                    </td>


                    <td class="px-2">
                     <span class="badge badge-success text-lg`"
                           v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }">
                       {{ item.is_active ? 'Active' : 'Inactive' }}</span>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="__edit(item)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="orange darken-2">fas fa-edit</v-icon>
                                </span>
                                <span class="navi-text">Edit Institution Type</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a  @click="__delete(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="red">fas fa-trash</v-icon>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="sortedItems.length == 0">
                    <td colspan="4" class="text-center">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="sortedItems.length > 0"
                  class="pull-right mt-7"
                  @input="_getAll"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number  :disabled="isLoading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <create-or-update ref="create-or-update" @refresh="_getAll"></create-or-update>
    </div>
  </v-app>
</template>
<script>
  import InstitutionTypesService from "@/services/academic/institution/type/InstitutionTypesService";
  const institutionService = new InstitutionTypesService()
  import CreateOrUpdate from "./CreateOrUpdate";
  export default {
  components:{
    CreateOrUpdate,
  },
  data() {
    return{
      isLoading:false,
      search:{
        title:'',
        is_active:'',
      },
      institutions_types:[],
      total: null,
      perPage: null,
      page: null,
      sort: {
        key: '',
        isAsc: false
      },
      status: [
        { name: 'Active', value: '1'},
        { name: 'Inactive', value: '0'},
      ],
    }
  },
  methods: {
    _getAll(){
      this.isLoading=true;
      institutionService
          .paginate(this.search, this.page)
          .then(response => {
            this.institutions_types=response.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.isLoading=false;
          })
          .catch((err) => {
          }).finally(()=>{
            this.isLoading = false;
      });
    },
    __search(){
      this._getAll();
    },
    sortedClass (key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc' }` : '';
    },
    sortBy (key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },
    __create(){
      this.$refs['create-or-update'].createForm();
    },
    __edit(item){
      this.$refs['create-or-update'].editForm(item);
    },
    __delete(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            institutionService
                .delete(item)
                .then((response) => {
                  this._getAll();
                  this.$snotify.success("City Info Sucessfully Deleted");
                })
                .catch((err) => {
                  this.isLoading = false;
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
  },
  mounted() {
    this._getAll();
  },
  computed:{
    sortedItems () {
      const list = this.institutions_types.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }

      return list;
    }
  },
}
</script>
<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>